.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  #bunnieasterz_logo {
    animation: App-logo-spin infinite 2s linear;
    animation-direction: alternate-reverse;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bunnieaster-page-header {

  font-family: "Copyduck";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(50px + 2vmin);
  color: white;
  font-weight: bold;
}

.bunnieaster-page-header h1 {

  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vh;
  color: #98af6d;
  text-shadow: 0.1vh 0.1vh 20px white;

}

.bunnieaster-page-header h2 {

  font-family: "Copyduck";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10vh;
  color: #5FBDFF;
  font-weight: bold;
}

.bunnieaster-page {

  text-align: center;
  font-family: 'Copyduck';
}

.bunnieaster-about {
  display: flex;
  margin-top: 5vh;
  padding-left: 50vh;
  padding-right: 50vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  text-align: center;
  font-family: 'Copyduck';
}

.bunnieaster-about h1 {

  font-size: 3vh;
  color: #ffdb00;
  text-shadow: 3px 2px #534406;

}

.bunnieaster-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width:70%;
  font-family: 'Copyduck';
}

.bunnieaster-faq h1 {
  font-size: 2vh;
  color: #C97902;

}

.bunnieaster-faq p {

  font-size: 2vh;
  color: #C97902;

}

.bunnieaster-soon {

  margin-top: 40vh;
  font-size: 6vh;
  color: #98af6d;
  text-shadow: 0vh 0vh 40px white;
  text-align: center;
  justify-content: center;
  font-family: "Copyduck"

}



.socialmedia-section {

  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
  width: 3vh;
}

.socialmedia {

  border-radius: 45px;
  border: 10px solid white;
  margin-left: 1vh;
  margin-right: 1vh;
  height: 3vh;
  width: 3vh;
  background-color: white;
  transition-duration: 200ms;
}


.socialmedia:hover {


  transition-duration: 200ms;
  height: 4vh;
  width: 4vh;
  background-color: #98af6d;
  border: 10px solid #98af6d;
  box-shadow: 0px 0px 20px 0.1px white;
}

.animation,.animation_layer,.animation_layer_mountain,.animation_layer_land{
  height: 100vh;

}

.animation {
  display: block;
  position: relative;
  z-index: 10;
}

.animation_layer{
  background-position: bottom center;
  background-size: auto 80vh;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
}
.animation_layer.parallax{
  position: fixed;
}



.animation_layer_mountain{
  background-position: bottom center;
  background-size: auto 100vh;
  background-repeat: no-repeat;
  height: 140vh;
  min-width: 140vh;
  width: 100%;
  position: absolute;
}
.animation_layer_mountain.parallax{
  position: fixed;
}

.animation_layer_land{
  background-position: bottom center;
  background-size: auto 22vh;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}
.animation_layer_mountain.parallax{
  position: fixed;
}




#layer1 {
  background-image: url(./mountain3.png);
}

#layer2 {
  background-image: url(./mountain2.png);

}
#layer3 {
  background-image: url(./mountain1.png);
}

#layer4 {
  background-image: url(./mountain4.png);
    filter:blur(1px);
}
#layer5 {
  background-image: url(./mountain5.png);
  filter:blur(1px);
}
#land{
  background-image: url(./land.png);
}


#cloud1 {
  background-image: url(./cloud1.png);
  filter:blur(5px);
}
#cloud2 {
  background-image: url(./cloud2.png);
  filter:blur(2px);
}
#cloud3 {
  background-image: url(./cloud3.png);
  filter:blur(1px);
}

#cloud4 {
  background-image: url(./cloud4.png);
  filter:blur(3px);
}

#eggs{
  background-image: url(./eggs2.png);
}

#bunnieasterz_logo {
  background: url(./Bunnieasterz.png) no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 40%;
  min-width: 50vh;
  margin-top: 5vh;
  margin-bottom: 10vh;
}



@keyframes App-logo-spin {
  from {
    transform: scale(1) rotate(-1deg);
  }
  to {
    transform: scale(1.05) rotate(1deg);
  }

}
